exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-default-list-page-template-js": () => import("./../../../src/templates/DefaultListPageTemplate.js" /* webpackChunkName: "component---src-templates-default-list-page-template-js" */),
  "component---src-templates-default-single-page-template-js": () => import("./../../../src/templates/DefaultSinglePageTemplate.js" /* webpackChunkName: "component---src-templates-default-single-page-template-js" */),
  "component---src-templates-posts-list-page-template-js": () => import("./../../../src/templates/PostsListPageTemplate.js" /* webpackChunkName: "component---src-templates-posts-list-page-template-js" */),
  "component---src-templates-posts-per-author-list-page-template-js": () => import("./../../../src/templates/PostsPerAuthorListPageTemplate.js" /* webpackChunkName: "component---src-templates-posts-per-author-list-page-template-js" */),
  "component---src-templates-posts-per-category-list-page-template-js": () => import("./../../../src/templates/PostsPerCategoryListPageTemplate.js" /* webpackChunkName: "component---src-templates-posts-per-category-list-page-template-js" */),
  "component---src-templates-work-single-page-template-js": () => import("./../../../src/templates/WorkSinglePageTemplate.js" /* webpackChunkName: "component---src-templates-work-single-page-template-js" */)
}

